import React, { ReactElement, ReactNode, useEffect } from 'react'

import { VIEWLIO_ENV } from '@viewlio/config/src/viewlioConfig'
import { MetaEntry } from '@viewlio/types/src/contentful'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { AppContextProvider } from 'providers'
import { IntlProvider } from 'react-intl'

import { AppHead } from 'components/common/AppHead'
import { AppScripts } from 'components/common/AppScripts'
import { ImpersonationNotice } from 'components/common/ImpersonationNotice'
import { ErrorBoundary } from 'lib/bugsnag'
import {
  AppWrapper,
  CreateStoresConfig,
  useGlobalStore,
} from 'stores'
import { camelizeKeys } from 'utils/caseConversion'
import { datadogRumScript, datadogLogsScript, accessibeScript } from 'utils/script'
import { Translations } from 'utils/translations'

import 'styles/styles.scss'

type PageProps = {
  __viewlioInitialStoreData: CreateStoresConfig
  alternateLangs?: Record<string, string>
  metaEntry: MetaEntry
  personalizedEntriesIds?: string[]
  statusCode?: number
  translations: Translations
}

export type PageWithGetLayout<T = AppProps<PageProps>['Component']> = T & {
  getLayout?: (page: ReactElement, pageProps: Record<string, any>) => ReactNode
}

export type ViewlioAppProps = AppProps<PageProps> & {
  Component: PageWithGetLayout
}

const Viewlio: React.FC<ViewlioAppProps> = ({
  Component,
  pageProps,
}) => {
  if (pageProps?.statusCode >= 400) {
    return <Component {...pageProps} />
  }

  const {
    fetchShopper,
    locale,
    juulioStore: {
      code,
    },
  } = useGlobalStore()

  const {
    asPath,
    replace,
    query: snakeCaseQuery,
  } = useRouter()

  const query = camelizeKeys(snakeCaseQuery) as Record<string, string>

  const switchLocale = async () => {
    const [currentPath] = asPath.split('?')
    await replace(currentPath, currentPath, { locale: query.switchLocale })

    // Necessary to get the CloudFront lambda to set the NEXT_LOCALE cookie
    window.location.reload()
  }

  useEffect(() => {
    if (query.switchLocale && query.switchLocale !== locale) {
      switchLocale()
    }
  }, [query, locale])

  const {
    isFallback,
  } = useRouter()

  if (isFallback) {
    return null
  }

  const {
    alternateLangs,
    metaEntry,
    translations,
  } = pageProps as PageProps

  useEffect(() => {
    fetchShopper()
  }, [])

  const getLayout = Component.getLayout || ((page, _pageProps) => page)

  return (
    <ErrorBoundary>
      <IntlProvider
        messages={translations}
        locale={locale}
        textComponent='span'
      >
        <AppContextProvider>
          <div className={code}>
            <AppHead
              contentfulData={metaEntry}
              alternateLangs={alternateLangs}
            />
            <AppScripts />
            {getLayout(<Component {...pageProps} />, pageProps)}
            <ImpersonationNotice />
          </div>
        </AppContextProvider>
      </IntlProvider>
    </ErrorBoundary>
  )
}

const ViewlioWithStores: React.FC<ViewlioAppProps> = props => (
  <>
    <AppWrapper {...props}>
      <Viewlio {...props} />
    </AppWrapper>
    <script dangerouslySetInnerHTML={{
      __html: datadogRumScript,
    }} />
    <script dangerouslySetInnerHTML={{
      __html: datadogLogsScript,
    }} />
    {
      VIEWLIO_ENV === 'staging' && (
        <script dangerouslySetInnerHTML={{
          __html: accessibeScript,
        }} />
      )
    }
  </>
)

export default ViewlioWithStores
